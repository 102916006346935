import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAjesJm6eHbXDNnMtNmm9gMaw1u27gNcH4",
  authDomain: "last-stop-c6943.firebaseapp.com",
  projectId: "last-stop-c6943",
  storageBucket: "last-stop-c6943.appspot.com",
  messagingSenderId: "175727391340",
  appId: "1:175727391340:web:3025df67fedaed29e61e49",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { app, db };
