import React from "react";
import { useTypewriter, Cursor } from 'react-simple-typewriter';
import "./Main.css";

const Banner = () => {
  const [text] = useTypewriter({
    words: [
      "Good meals + Fast delivery guaranteed",
    ],
    loop: 0, // Set to 0 for infinite loop
    typeSpeed: 70,
    deleteSpeed: 70,
    delaySpeed: 1000,
  });

  return (
    <div className="banner-image">
      <div className="banner-text">
        <h2>Last Stop!</h2>
        <div className="typed-container">
          <span>{text}</span><Cursor />
        </div>
      </div>
    </div>
  );
};

export default Banner;
